<template>
  <el-card id="creatNums">
    <el-card>
      <header>
        <h1>个人私海</h1>
        <div class="search">
          <div class="left">
            <div class="left-wrap">
              <span>号码</span>
              <el-input
                v-model="activePhone"
                placeholder="请输入号码"
                size="small"
              ></el-input>
            </div>
            <div class="left-wrap">
              <span>企业</span>
              <el-input
                v-model="company"
                placeholder="请输入企业名称"
                size="small"
              ></el-input>
            </div>
            <div class="left-wrap">
              <span>行业</span>
              <el-input
                v-model="industry"
                placeholder="请输入行业"
                size="small"
              ></el-input>
            </div>
            <div class="left-wrap">
              <span>客户来源</span>
              <el-input
                v-model="customerSource"
                placeholder="请输入客户来源"
                size="small"
              ></el-input>
            </div>
            <div class="left-wrap">
              <span>备注</span>
              <el-input
                v-model="remarkSearch"
                placeholder="请输入备注"
                size="small"
              ></el-input>
            </div>
            <div class="left-wrap" v-if="realname == 'admin'">
              <span>企业</span>
              <el-input
                v-model="company"
                placeholder="请输入公司名称"
                filterable
                size="small"
              ></el-input>
            </div>
            <div class="left-wrap" v-if="roleName == '组长'">
              <span>员工</span>
              <el-select
                v-model="id1"
                placeholder="请选择员工"
                filterable
                size="small"
              >
                <el-option
                  v-for="item in underUser"
                  :key="item.id"
                  :label="item.realname"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div
              class="left-wrap"
              v-if="roleName == '管理员' || roleName == '企业'"
            >
              <span>部门</span>
              <el-select
                v-model="departId"
                placeholder="请选择部门"
                filterable
                size="small"
              >
                <el-option
                  v-for="item in underDepart"
                  :key="item.id"
                  :label="item.departName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div
              class="left-wrap"
              v-if="roleName == '管理员' || roleName == '企业'"
            >
              <span>员工</span>
              <el-select
                v-model="userId"
                placeholder="请选择员工"
                filterable
                size="small"
              >
                <el-option
                  v-for="item in underUser"
                  :key="item.id"
                  :label="item.realname"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="left-wrap">
              <span>导入时间</span>
              <el-date-picker
                v-model="createTime"
                type="datetimerange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="center"
                size="small"
              >
              </el-date-picker>
            </div>
            <div class="left-wrap">
              <span>修改时间</span>
              <el-date-picker
                v-model="changeTime"
                type="datetimerange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="center"
                size="small"
              >
              </el-date-picker>
            </div>
            <div class="left-wrap">
              <span>分配时间</span>
              <el-date-picker
                v-model="getTime"
                type="datetimerange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="center"
                size="small"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="right">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              size="small"
              class="searchBtn"
              >查找</el-button
            >
            <el-button
              class="reset"
              type="primary"
              icon="el-icon-refresh"
              @click="reset()"
              size="small"
              >重置</el-button
            >
            <el-button
              type="primary"
              @click="distributeNums()"
              size="small"
              class="distributeNums"
              v-if="roleName != '人员'"
              >分配号码</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-folder-opened"
              @click="allNums()"
              size="small"
              class="allNums"
              >批量导入号码</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-folder-opened"
              @click="addNums()"
              size="small"
              class="addNums"
              >添加号码</el-button
            >
            <el-button
              type="danger"
              @click="moreDelete()"
              class="delete"
              size="small"
              icon="el-icon-delete"
              >批量释放</el-button
            >
          </div>
        </div>
      </header>
      <el-divider></el-divider>
      <main>
        <el-tabs v-model="activeData" @tab-click="clickData">
          <el-tab-pane label="无状态" name="wzt">
            <el-table
              :data="tableData"
              border
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              @row-dblclick="dblclick"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="userRealname"
                label="归属人"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="activePhone"
                label="手机号码"
                align="center"
                v-if="(!hideNum && id != 5051) || roleName == '企业'"
              ></el-table-column>
              <el-table-column
                prop="phone"
                label="手机号码"
                align="center"
                v-if="(hideNum || id == 5051) && roleName != '企业'"
              ></el-table-column>
              <el-table-column
                prop="company"
                label="企业名称"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerPhone"
                label="姓名"
                align="center"
              ></el-table-column>

              <el-table-column
                prop="industry"
                label="行业"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="address"
                label="地址"
                align="center"
              ></el-table-column>
              <el-table-column prop="status" label="状态" align="center">
                <template #default="scope">
                  <span v-if="scope.row.status == 0">一般客户</span>
                  <span v-if="scope.row.status == 1">意向客户</span>
                  <span v-if="scope.row.status == 2">无意向客户</span>
                  <span v-if="scope.row.status == 3">成交客户</span>
                  <span v-if="scope.row.status == 4">黑名单</span>
                  <span v-if="scope.row.status == 5">无状态</span>
                  <span v-if="scope.row.status == 6">未接通</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="导入时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="changeTime"
                label="修改时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="remarks"
                label="备注"
                align="center"
                class="hide"
              ></el-table-column>
              <el-table-column
                prop="choosePeople"
                label="决策人"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerScale"
                label="公司规模"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerSource"
                label="客户来源"
                align="center"
              ></el-table-column>
              <el-table-column
                label="操作"
                width="400"
                align="center"
                fixed="right"
              >
                <template #default="scope">
                  <el-button
                    type="success"
                    @click="call(scope)"
                    v-if="roleName != '企业'"
                    size="small"
                    >拨打</el-button
                  >
                  <!-- 释放号码 -->
                  <el-button
                    type="danger"
                    @click="removeButton(scope.row, scope.$index)"
                    size="small"
                    >释放</el-button
                  >
                 
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="未拨打" name="wbd">
            <el-table
              :data="tableData"
              border
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              @row-dblclick="dblclick"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="userRealname"
                label="归属人"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="activePhone"
                label="手机号码"
                align="center"
                v-if="(!hideNum && id != 5051) || roleName == '企业'"
              ></el-table-column>
              <el-table-column
                prop="phone"
                label="手机号码"
                align="center"
                v-if="(hideNum || id == 5051) && roleName != '企业'"
              ></el-table-column>
              <el-table-column
                prop="company"
                label="企业名称"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerPhone"
                label="姓名"
                align="center"
              ></el-table-column>

              <el-table-column
                prop="industry"
                label="行业"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="address"
                label="地址"
                align="center"
              ></el-table-column>
              <el-table-column prop="status" label="状态" align="center">
                <template #default="scope">
                  <span v-if="scope.row.status == 0">一般客户</span>
                  <span v-if="scope.row.status == 1">意向客户</span>
                  <span v-if="scope.row.status == 2">无意向客户</span>
                  <span v-if="scope.row.status == 3">成交客户</span>
                  <span v-if="scope.row.status == 4">黑名单</span>
                  <span v-if="scope.row.status == 5">无状态</span>
                  <span v-if="scope.row.status == 6">未接通</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="导入时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="changeTime"
                label="修改时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="remarks"
                label="备注"
                align="center"
                class="hide"
              ></el-table-column>
              <el-table-column
                prop="choosePeople"
                label="决策人"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerScale"
                label="公司规模"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerSource"
                label="客户来源"
                align="center"
              ></el-table-column>
              <el-table-column
                label="操作"
                width="160"
                align="center"
                fixed="right"
              >
                <template #default="scope">
                  <el-button
                    type="success"
                    @click="call(scope)"
                    v-if="roleName != '企业'"
                    size="small"
                    >拨打</el-button
                  >
                  <!-- 释放号码 -->
                  <el-button
                    type="danger"
                    @click="removeButton(scope.row, scope.$index)"
                    size="small"
                    >释放</el-button
                  >
                   <!-- 更新号码 -->
                   <!-- <el-button
                    type="danger"
                    @click="updataNumber(scope.row)"
                    size="small"
                    >更新</el-button
                  > -->
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="未接通" name="wjt">
            <el-table
              :data="tableData"
              border
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              @row-dblclick="dblclick"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="userRealname"
                label="归属人"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="activePhone"
                label="手机号码"
                align="center"
                v-if="(!hideNum && id != 5051) || roleName == '企业'"
              ></el-table-column>
              <el-table-column
                prop="phone"
                label="手机号码"
                align="center"
                v-if="(hideNum || id == 5051) && roleName != '企业'"
              ></el-table-column>
              <el-table-column
                prop="company"
                label="企业名称"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerPhone"
                label="姓名"
                align="center"
              ></el-table-column>

              <el-table-column
                prop="industry"
                label="行业"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="address"
                label="地址"
                align="center"
              ></el-table-column>
              <el-table-column prop="status" label="状态" align="center">
                <template #default="scope">
                  <span v-if="scope.row.status == 0">一般客户</span>
                  <span v-if="scope.row.status == 1">意向客户</span>
                  <span v-if="scope.row.status == 2">无意向客户</span>
                  <span v-if="scope.row.status == 3">成交客户</span>
                  <span v-if="scope.row.status == 4">黑名单</span>
                  <span v-if="scope.row.status == 5">无状态</span>
                  <span v-if="scope.row.status == 6">未接通</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="导入时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="changeTime"
                label="修改时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="remarks"
                label="备注"
                align="center"
                class="hide"
              ></el-table-column>
              <el-table-column
                prop="choosePeople"
                label="决策人"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerScale"
                label="公司规模"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="customerSource"
                label="客户来源"
                align="center"
              ></el-table-column>
              <el-table-column
                label="操作"
                width="400"
                align="center"
                fixed="right"
              >
                <template #default="scope">
                  <el-button
                    type="success"
                    @click="call(scope)"
                    v-if="roleName != '企业'"
                    size="small"
                    >拨打</el-button
                  >
                  <!-- 释放号码 -->
                  <el-button
                    type="danger"
                    @click="removeButton(scope.row, scope.$index)"
                    size="small"
                    >释放</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>

        <!--分页  -->
        <div class="pagination">
          <el-pagination
            background
            layout="total, prev, pager, next,sizes"
            :current-page="pageIndex"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100, 200, 300]"
            :total="pageTotal"
            @size-change="handleSizeChange"
            @current-change="handlePageChange"
          ></el-pagination>
        </div>
      </main>
    </el-card>

    <!-- 编辑弹出框 -->
    <el-dialog
      title="编辑"
      :visible.sync="updateVisible"
      :before-close="updateClose"
    >
      <el-form ref="form" :model="form" label-width="100px" class="update">
        <el-form-item label="客户号码">
          <el-input
            v-model="form.phone"
            placeholder="请输入客户号码"
            v-if="(hideNum || id == 5051) && roleName != '企业'"
          ></el-input>
          <el-input
            v-model="form.activePhone"
            placeholder="请输入客户号码"
            v-if="(!hideNum && id != 5051) || roleName == '企业'"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户姓名">
          <el-input
            v-model="form.customerPhone"
            placeholder="请输入客户姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input
            v-model="form.company"
            placeholder="请输入公司名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="决策人">
          <el-input
            v-model="form.choosePeople"
            placeholder="请输入决策人"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司规模">
          <el-input
            v-model="form.customerScale"
            placeholder="请输入公司规模"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户来源">
          <el-input
            v-model="form.customerSource"
            placeholder="请输入客户来源"
          ></el-input>
        </el-form-item>
        <el-form-item label="行业">
          <el-input v-model="form.industry" placeholder="请输入行业"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.address" placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="update_status" style="line-height: 30px">
            <el-radio :label="0">一般客户</el-radio>
            <el-radio :label="1">意向客户</el-radio>
            <el-radio :label="2">无意向客户</el-radio>
            <el-radio :label="3">成交客户</el-radio>
            <el-radio :label="4">黑名单</el-radio>
            <el-radio :label="5">无状态</el-radio>
            <el-radio :label="6">未接通</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="跟进记录">
          <el-input
            type="textarea"
            v-model="form.historyRecord"
            placeholder="请输入跟进记录(备注)"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer style="position: relative">
        <span class="dialog-footer">
          <el-button type="primary" @click="updateSubmit" style="width: 100%"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 释放弹出框 -->
    <el-dialog title="释放" :visible.sync="removeVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="释放位置">
          <el-select v-model="removeValue" @change="oneSlt">
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="removeVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="confirm()" v-if="removeValue == 1"
            >确 定</el-button
          >
          <el-button
            type="primary"
            @click="depDetails"
            v-else-if="removeValue == 0"
            :disabled="disabled1"
            >下一步</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 部门选择弹出框 -->
    <el-dialog title="释放" :visible.sync="depVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="释放位置">
          <el-select
            v-model="removeParams.departId"
            placeholder="请选择释放位置"
            filterable
          >
            <el-option
              v-for="item in freeDepart"
              :key="item.id"
              :label="item.departName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            @click="(removeVisible = true), (depVisible = false)"
            >上一步</el-button
          >
          <el-button type="primary" @click="step">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 批量释放弹出框 -->
    <el-dialog title="批量释放" :visible.sync="batchRemoveVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="释放位置">
          <el-select v-model="batchRemoveValue" @change="oneSltBatch">
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="batchRemoveVisible = false"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="confirm1()"
            v-if="batchRemoveValue == 1"
            >确 定</el-button
          >
          <el-button
            type="primary"
            @click="depDetails1"
            v-else-if="batchRemoveValue == 0"
            :disabled="disabled2"
            >下一步</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 批量部门选择弹出框 -->
    <el-dialog title="批量释放" :visible.sync="depVisibleBatch" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="释放位置">
          <el-select v-model="departId" placeholder="请选择释放位置" filterable>
            <el-option
              v-for="item in freeDepart"
              :key="item.id"
              :label="item.departName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            @click="(batchRemoveVisible = true), (depVisibleBatch = false)"
            >上一步</el-button
          >
          <el-button type="primary" @click="step1()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 添加号码弹出框 -->
    <el-dialog title="添加号码" :visible.sync="addOneVisible">
      <el-form ref="form" :model="form" label-width="100px" class="update">
        <el-form-item label="客户号码">
          <el-input
            v-model="form.activePhone"
            placeholder="请输入客户号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户姓名">
          <el-input
            v-model="form.customerPhone"
            placeholder="请输入客户姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名称" v-if="realname == 'admin'">
          <el-input
            v-model="form.company"
            placeholder="输入公司名称分配至公司公海"
          ></el-input>
        </el-form-item>
        <el-form-item label="部门">
          <el-input
            v-model="form.departName"
            placeholder="输入部门名称分配至部门海"
          ></el-input>
        </el-form-item>
        <el-form-item label="决策人">
          <el-input
            v-model="form.choosePeople"
            placeholder="请输入决策人"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司规模">
          <el-input
            v-model="form.customerScale"
            placeholder="请输入公司规模"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户来源">
          <el-input
            v-model="form.customerSource"
            placeholder="请输入客户来源"
          ></el-input>
        </el-form-item>
        <el-form-item label="行业">
          <el-input v-model="form.industry" placeholder="请输入行业"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.address" placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="update_status" style="line-height: 30px">
            <el-radio :label="0">一般客户</el-radio>
            <el-radio :label="1">意向客户</el-radio>
            <el-radio :label="2">无意向客户</el-radio>
            <el-radio :label="3">成交客户</el-radio>
            <el-radio :label="4">黑名单</el-radio>
            <el-radio :label="5">无状态</el-radio>
            <el-radio :label="6">未接通</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remarks" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="addOneVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="addOneSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 批量导入号码弹出框 -->
    <el-dialog title="批量导入号码" :visible.sync="numShow" width="30%">
      <div class="content">
        <el-button
          type="success"
          icon="el-icon-download"
          @click="upload()"
          class="uploadBtn"
          >点击下载模板</el-button
        >
        <!-- <a
          style="
            height: 40px;
            background-color: #67c23a;
            border-radius: 5px;
            color: #ffffff;
            line-height: 40px;
            text-align: center;
          "
          href="http://113.250.189.239:9005/phone.xls"
          target="_blank"
          >点击下载模板</a
        > -->

        <el-upload
          class="upload-demo"
          action=""
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :auto-upload="false"
          multiple
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :on-change="changeUpload"
          drag="true"
          width="100%"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="numShow = false">取 消</el-button>
          <el-button @click="numsSubmit" :disabled="disabled" type="primary"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 分配号码弹出框 -->
    <el-dialog title="分配号码" :visible.sync="distributeShow" width="30%">
      <el-switch
        v-model="departUser"
        active-text="选择个人"
        inactive-text="选择部门"
        v-if="roleName != '组长'"
      >
      </el-switch>
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        style="margin-top: 30px"
      >
        <el-form-item label="部门" v-if="roleName != '组长'">
          <el-select
            v-model="departId"
            placeholder="请选择部门，分配至部门海"
            :disabled="departUser"
            filterable
            v-if="roleName != '组长'"
          >
            <el-option
              v-for="item in underDepart"
              :key="item.id"
              :label="item.departName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="个人">
          <el-select
            v-model="userId"
            filterable
            placeholder="请选择个人"
            :disabled="!departUser"
          >
            <el-option
              v-for="item in underUser"
              :key="item.id"
              :label="item.realname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="distributeShow = false" style="margin-right: 10px"
          >退 出</el-button
        >
        <span class="dialog-footer">
          <el-button type="primary" @click="distributeSubmit"
            >确认提交</el-button
          >
        </span>
      </template>
    </el-dialog>

    <el-dialog
      title="回拨已拨打"
      :visible.sync="callVisible"
      width="30%"
      center
    >
      <span
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          color: red;
        "
        >请在20秒内用手机拨打:{{ xphone }}</span
      >
    </el-dialog>

    <!-- sip弹出框 -->
    <el-dialog
      title="SIP呼叫"
      :visible.sync="sipCallVisible"
      width="30%"
      :before-close="handleClose"
      @close="hang_up"
    >
      <sipCall
        :callPhoneNum="sipPhone"
        :callPhoneData="callData"
        @showValue="showMethod"
      ></sipCall>
    </el-dialog>
  </el-card>
</template>

<script>
import sipCall from "@/views/sip/sipCall.vue";
import clientsCharts from "@/views/charts/clientsCharts.vue";
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  phoneFindOneHistoryPhone,
  phoneFindUser,
  phoneList,
  phoneDistribute,
  phoneDelete,
  phoneUpdate,
  phoneImportPhone,
  phoneAdd,
} from "@/api/phone";
import { sysDepartList } from "@/api/sys_depart";
import { callAppBind,update_call } from "@/api/call";

export default {
  components: { clientsCharts, sipCall },
  data() {
    return {
      ua: "",
      callData: "", // 拨号后接口数据
      sipCallVisible: false,
      updateVisible: false,
      activeData: "wzt",
      activeName: "update",
      update_status: null,
      fileList: [],
      depVisible: false,
      depVisibleBatch: false,
      disabled1: true,
      disabled2: true,
      options3: [
        {
          value: 0,
          label: "a",
        },
        {
          value: 1,
          label: "b",
        },
      ],
      options2: [
        {
          value: 0,
          label: "部门公海",
        },
        {
          value: 1,
          label: "公司公海",
        },
      ],
      textarea: "",
      drawer: false,
      direction: "rtl",
      choosePeople: null,
      customerScale: null,
      customerSource: null,
      createTime: null,
      changeTime: null,
      getTime: null,
      removeIndex: [],
      sipPhone: null,
      callNum: null,
      industry: null,
      address: null,
      id: null,
      id1: null,
      oneNum: 0,
      twoNum: 0,
      activePhone: null,
      company: null,
      value: null,
      disabled: false,
      removeParams: {}, //释放列表时需要的参数
      removeValue: "", // 释放选择框选中的项
      batchRemoveValue: "", // 批量释放选择框选中的项
      removeVisible: false, //释放弹出框
      batchRemoveVisible: false, //批量释放弹出框
      addOneVisible: false, //添加弹出框
      distributeShow: false, //分配弹出框
      departUser: true,
      departId: null,
      userId: null,
      departShow: true,
      upRecords: [],
      remarkSearch: null,
      form: {
        phone: null,
        keqing_remarks: "",
        historyRecord: null,
        choosePeople: null,
        customerScale: null,
        customerSource: null,
        createTime: null,
        changeTime: null,
        getTime: null,
        sendTime: "",
        activePhone: "",
        customerPhone: "",
        company: null,
        departName: null,
        remarks: "",
        industry: null,
        address: null,
        status: "",
        userId: null,
        departId: "",
        id: "",
        id1: null,
        roleId: "",
      },
      nums: [
        {
          id: 0,
          numName: "部门公海",
        },
        {
          id: 1,
          numName: "公司公海",
        },
      ],
      changeNums: null,
      numsArray: [], //选中号码的ID
      freeDepart: [],
      underDepart: [],
      underUser: [],
      remarks: "", //备注（只传不回显）
      numShow: false, //批量导入弹出框
      realname: null,
      indexArr: [],
      isBtn: false,
      hideNum: false,
      roleName: null,
      ms_username: null,
      xphone: null,
      times: 20,
      callVisible: false,
      onecallVisible: false,
      historyRecord: null,
      historys: true,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    this.$nextTick(function () {
      let bool = localStorage.getItem("hideNum");
      bool == "false" ? (this.hideNum = false) : (this.hideNum = true);
      console.log("hideNum参数", this.hideNum);
    });
    this.realname = localStorage.getItem("realname");
    this.roleName = localStorage.getItem("roleName");
    this.ms_username = localStorage.getItem("ms_username");
    this.id = localStorage.getItem("id") * 1;
    if (this.roleName == "管理员" || this.roleName == "企业") {
      this.findUser();
    }
    if (this.roleName == "组长") {
      this.findUser1();
    }
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.getList();
    this.findUser();
    this.findUser1();
  },
  watch: {
    departUser() {
      this.departId = null;
      this.userId = null;
    },
    tableData: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.$nextTick(() => {
            //newVal是新的表格数据，[8] 8是表格数据要默认选中的索引
            this.indexArr.forEach((item) => {
              this.$refs.multipleTable.toggleRowSelection(newVal[item], true);
            });
          });
        }
      },
      // immediate: true
    },
  },
  inject: ["reload"],
  methods: {
    // 双击弹出框
    dblclick(row) {
      this.form = { ...row };
      this.update_status = null;
      this.updateVisible = true;
    },
    // 挂断
    hang_up() {
      if (this.ua) {
        this.ua.stop();
      }
    },
    // sip挂断关闭弹框
    showMethod(obj) {
      this.sipCallVisible = obj.bool;
      this.ua = obj.ua;
    },
    // 编辑关闭回调
    handleClose(done) {
      this.$confirm("如需关闭弹框，请挂断SIP通话")
        .then((_) => {
          // done();
        })
        .catch((_) => {});
    },
    // 双击切换事件
    handleClick(e) {
      if (e.index == "1") {
        this.record();
      }
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },

    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    //选择table表格
    handleSelectionChange(val) {
      this.numsArray = [];
      val.forEach((item) => {
        this.numsArray.push(item.id);
      });
      // console.log(this.numsArray);
    },
    //查询用户下所有员工
    findUser() {
      let params = {};
      let res = phoneFindUser(params);
      res.then((res) => {
        this.underDepart = res.data.data.depart;
        this.underUser = res.data.data.user;
      });
    },
    //释放具体部门
    findUser2() {
      // let gsId = localStorage.getItem("id") * 1;
      let bmId = localStorage.getItem("parentId") * 1;
      let params = {
        topId: "",
      };
      if (this.roleName == "公司") {
        // params.topId = gsId;
      } else {
        params.topId = bmId;
      }
      let res = sysDepartList(params);
      res.then((res) => {
        let data = res.data.data;
        if (!data) {
          Message.error("部门为空");
          this.depVisible = false;
          this.removeVisible = false;
          this.batchRemoveVisible = false;
          this.depVisibleBatch = false;
          return false;
        }
        for (let key in data) {
          this.depId = data[key].id;
          this.freeDepart = data;
        }
      });
    },

    findUser1() {
      let params = {};
      let res = phoneFindUser(params);
      res.then((res) => {
        this.underDepart = res.data.data.depart;
        this.underUser = res.data.data.user;
      });
    },

    // 补0
    fillZero(n) {
      return n <= 9 ? "0" + n : n;
    },

    // 获取年月日的函数
    getYmd(data) {
      let y = data.getFullYear(); //年
      let m = data.getMonth() + 1; //月
      let d = data.getDate(); //日
      return [y, this.fillZero(m), this.fillZero(d)].join("-");
    },

    // 获取时间的函数
    getHms(times) {
      let h = times.getHours(); //时
      let m = times.getMinutes(); //分
      let s = times.getSeconds(); //秒
      return [this.fillZero(h), this.fillZero(m), this.fillZero(s)].join(":");
    },
    //搜索
    handleSearch() {
      // alert(111)
      let obj = {};
      let obj1 = {};
      let obj2 = {};

      // 转换日期
      let sta_createTime = null;
      let end_createTime = null;
      if (this.createTime) {
        sta_createTime =
          this.getYmd(this.createTime[0]) +
          " " +
          this.getHms(this.createTime[0]);
        end_createTime =
          this.getYmd(this.createTime[1]) +
          " " +
          this.getHms(this.createTime[1]);
      }

      let sta_changeTime = null;
      let end_changeTime = null;
      if (this.changeTime) {
        sta_changeTime =
          this.getYmd(this.changeTime[0]) +
          " " +
          this.getHms(this.changeTime[0]);
        end_changeTime =
          this.getYmd(this.changeTime[1]) +
          " " +
          this.getHms(this.changeTime[1]);
      }

      let sta_getTime = null;
      let end_getTime = null;
      if (this.getTime) {
        sta_getTime =
          this.getYmd(this.getTime[0]) + " " + this.getHms(this.getTime[0]);
        end_getTime =
          this.getYmd(this.getTime[1]) + " " + this.getHms(this.getTime[1]);
      }

      let params = {};
      this.page = 1;
      if (this.cutIndex == 0 || !this.cutIndex) {
        if (this.roleName == "管理员" || this.roleName == "企业") {
          params = {
            status: 5,
            page: this.page,
            size: this.size,
            activePhone: this.activePhone,
            userId: this.userId,
            depart_id: this.departId,
            company: this.company,
            sta_createTime: sta_createTime,
            end_createTime: end_createTime,
            sta_changeTime: sta_changeTime,
            end_changeTime: end_changeTime,
            sta_getTime: sta_getTime,
            end_getTime: end_getTime,
            // industry: this.industry,
            address: this.address,
            // choosePeople: this.choosePeople,
            // customerScale: this.customerScale,
            customerSource: this.customerSource,
            industry: this.industry,
          };
        } else {
          params = {
            status: 5,
            page: this.page,
            size: this.size,
            userId: this.id1,
            industry: this.industry,
            address: this.address,
            activePhone: this.activePhone,
            company: this.company,
            sta_createTime: sta_createTime,
            end_createTime: end_createTime,
            sta_changeTime: sta_changeTime,
            end_changeTime: end_changeTime,
            sta_getTime: sta_getTime,
            end_getTime: end_getTime,
            // choosePeople: this.choosePeople,
            // customerScale: this.customerScale,
            customerSource: this.customerSource,
          };
        }
      } else if (this.cutIndex == 1) {
        if (this.roleName == "管理员" || this.roleName == "企业") {
          params = {
            noCall: 1,
            page: this.page,
            size: this.size,
            activePhone: this.activePhone,
            userId: this.userId,
            depart_id: this.departId,
            company: this.company,
            sta_createTime: sta_createTime,
            end_createTime: end_createTime,
            sta_changeTime: sta_changeTime,
            end_changeTime: end_changeTime,
            sta_getTime: sta_getTime,
            end_getTime: end_getTime,
            industry: this.industry,
            address: this.address,
            // choosePeople: this.choosePeople,
            // customerScale: this.customerScale,
            customerSource: this.customerSource,
          };
        } else {
          params = {
            noCall: 1,
            page: this.page,
            size: this.size,
            userId: this.id1,
            industry: this.industry,
            address: this.address,
            activePhone: this.activePhone,
            company: this.company,
            sta_createTime: sta_createTime,
            end_createTime: end_createTime,
            sta_changeTime: sta_changeTime,
            end_changeTime: end_changeTime,
            sta_getTime: sta_getTime,
            end_getTime: end_getTime,
            // choosePeople: this.choosePeople,
            // customerScale: this.customerScale,
            customerSource: this.customerSource,
          };
        }
      } else if (this.cutIndex == 2) {
        if (this.roleName == "管理员" || this.roleName == "企业") {
          params = {
            status: 6,
            page: this.page,
            size: this.size,
            activePhone: this.activePhone,
            userId: this.userId,
            depart_id: this.departId,
            company: this.company,
            sta_createTime: sta_createTime,
            end_createTime: end_createTime,
            sta_changeTime: sta_changeTime,
            end_changeTime: end_changeTime,
            sta_getTime: sta_getTime,
            end_getTime: end_getTime,
            industry: this.industry,
            address: this.address,
            // choosePeople: this.choosePeople,
            // customerScale: this.customerScale,
            customerSource: this.customerSource,
          };
        } else {
          params = {
            status: 6,
            page: this.page,
            size: this.size,
            userId: this.id1,
            industry: this.industry,
            address: this.address,
            activePhone: this.activePhone,
            company: this.company,
            sta_createTime: sta_createTime,
            end_createTime: end_createTime,
            sta_changeTime: sta_changeTime,
            end_changeTime: end_changeTime,
            sta_getTime: sta_getTime,
            end_getTime: end_getTime,
            // choosePeople: this.choosePeople,
            // customerScale: this.customerScale,
            customerSource: this.customerSource,
          };
        }
      }

      if (this.remarkSearch) {
        params.remarks = this.remarkSearch;
      }
      let res = phoneList(params);
      let res1 = phoneFindUser;
      res.then((a) => {
        this.tableData = a.data.data;
        this.pageTotal = a.data.total;
        a.data.data.forEach((item) => {
          let str = item.activePhone;
          item.phone = str.substring(0, 3) + "****" + str.substring(7);
        });
      });
      res1.then((b) => {
        this.underDepart = b.data.data.depart;
        this.underUser = b.data.data.user;
      });
    },
    //重置
    reset() {
      // (this.createTime = null),
      //   (this.changeTime = null),
      //   (this.getTime = null),
      //   (this.activePhone = null);
      // this.userId = null;
      // this.departId = null;
      // this.value = null;
      // this.changeNums = null;
      // this.company = null;
      // this.id1 = null;
      // this.getList();
      this.reload();
    },
    //分配号码
    distributeNums() {
      this.findUser();
      this.departId = null;
      this.userId = null;
      this.distributeShow = true;
    },
    //分配号码确认提交
    distributeSubmit() {
      if (this.userId == "" || this.userId == null) {
        let params = {
          listId: this.numsArray,
          departId: this.departId,
        };
        let res = phoneDistribute(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
          } else {
            Message.error(res.data.message);
          }
          this.distributeShow = false;
          this.getList();
        });
      } else {
        let params = {
          listId: this.numsArray,
          userId: this.userId,
        };
        let res = phoneDistribute(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
            this.numsArray = [];
          } else {
            Message.error(res.data.message);
            this.numsArray = [];
          }
          this.distributeShow = false;
          this.getList();
        });
      }
    },
    // 展示
    getList(e) {
      let params = {};
      if (this.cutIndex == 0 || !this.cutIndex) {
        if (this.roleName == "管理员" || this.roleName == "企业") {
          params = {
            page: this.page,
            size: this.size,
            status: 5,
            activePhone: this.activePhone,
            userId: this.userId,
            depart_id: this.departId,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            industry: this.industry,
            address: this.address,
            customerSource: this.customerSource,
          };
        } else {
          params = {
            page: this.page,
            size: this.size,
            industry: this.industry,
            address: this.address,
            userId: this.id1,
            status: 5,
            activePhone: this.activePhone,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            customerSource: this.customerSource,
          };
        }
      } else if (this.cutIndex == 1) {
        if (this.roleName == "管理员" || this.roleName == "企业") {
          params = {
            page: this.page,
            size: this.size,
            noCall: 1,
            activePhone: this.activePhone,
            userId: this.userId,
            depart_id: this.departId,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            industry: this.industry,
            address: this.address,
            customerSource: this.customerSource,
          };
        } else {
          params = {
            page: this.page,
            size: this.size,
            industry: this.industry,
            address: this.address,
            userId: this.id1,
            noCall: 1,
            activePhone: this.activePhone,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            customerSource: this.customerSource,
          };
        }
      } else if (this.cutIndex == 2) {
        if (this.roleName == "管理员" || this.roleName == "企业") {
          params = {
            page: this.page,
            size: this.size,
            status: 6,
            activePhone: this.activePhone,
            userId: this.userId,
            depart_id: this.departId,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            industry: this.industry,
            address: this.address,
            customerSource: this.customerSource,
          };
        } else {
          params = {
            page: this.page,
            size: this.size,
            industry: this.industry,
            address: this.address,
            userId: this.id1,
            status: 6,
            activePhone: this.activePhone,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            customerSource: this.customerSource,
          };
        }
      }
      let res = phoneList(params);
      res.then((res) => {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;
        res.data.data.forEach((item) => {
          let str = item.activePhone;
          item.phone = str.substring(0, 3) + "****" + str.substring(7);
        });
      });
    },
    // 展示切换
    clickData(e) {
      this.cutIndex = e.index;
      let params = {};
      if (this.cutIndex == "0") {
        if (this.roleName == "管理员" || this.roleName == "企业") {
          params = {
            page: this.page,
            size: this.size,
            status: 5,
            activePhone: this.activePhone,
            userId: this.userId,
            depart_id: this.departId,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            industry: this.industry,
            address: this.address,
            customerSource: this.customerSource,
          };
        } else {
          params = {
            page: this.page,
            size: this.size,
            industry: this.industry,
            address: this.address,
            userId: this.id1,
            status: 5,
            activePhone: this.activePhone,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            customerSource: this.customerSource,
          };
        }
      } else if (this.cutIndex == "1") {
        if (this.roleName == "管理员" || this.roleName == "企业") {
          params = {
            page: this.page,
            size: this.size,
            noCall: 1,
            activePhone: this.activePhone,
            userId: this.userId,
            depart_id: this.departId,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            industry: this.industry,
            address: this.address,
            customerSource: this.customerSource,
          };
        } else {
          params = {
            page: this.page,
            size: this.size,
            industry: this.industry,
            address: this.address,
            userId: this.id1,
            noCall: 1,
            activePhone: this.activePhone,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            customerSource: this.customerSource,
          };
        }
      } else if (this.cutIndex == "2") {
        if (this.roleName == "管理员" || this.roleName == "企业") {
          params = {
            page: this.page,
            size: this.size,
            status: 6,
            activePhone: this.activePhone,
            userId: this.userId,
            depart_id: this.departId,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            industry: this.industry,
            address: this.address,
            customerSource: this.customerSource,
          };
        } else {
          params = {
            page: this.page,
            size: this.size,
            industry: this.industry,
            address: this.address,
            userId: this.id1,
            status: 6,
            activePhone: this.activePhone,
            company: this.company,
            createTime: this.createTime,
            changeTime: this.changeTime,
            getTime: this.getTime,
            choosePeople: this.choosePeople,
            customerScale: this.customerScale,
            customerSource: this.customerSource,
          };
        }
      }
      let res = phoneList(params);
      res.then((res) => {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;
        res.data.data.forEach((item) => {
          let str = item.activePhone;
          item.phone = str.substring(0, 3) + "****" + str.substring(7);
        });
      });
    },
    // 编辑关闭回调
    updateClose(done) {
      this.$confirm("点击编辑页的确定关闭弹出框")
        .then((_) => {
          // done();
        })
        .catch((_) => {});
    },
    //拨打按钮
    call(row) {
      console.log("查看是不是row", row);
      // console.log(12346, row.row);
      // this.updateVisible = true;
      this.form = row.row;
      this.update_status = null;
      let parentId = JSON.parse(localStorage.getItem("parentId"));
      this.sipPhone = row.row.activePhone;
      let params = {
        passivePhone: this.sipPhone,
        activePhone: this.ms_username,
        parentId,
      };
      let res = callAppBind(params);
      console.log("callAppBind", res);
      res.then((res) => {
        this.callData = res;
        if (res.data.statusCode == "00000") {
          Message.success(res.data);
          this.updateVisible = true;
          //判断是否是AXB呼叫模式
          if (res.data.data.sip) {
            // console.log("sipsip");
            this.sipCallVisible = true;
            // this.callNum;
          } else if (res.data != null && !res.data.data.sip) {
            // console.log(res.data.data);
            this.xphone = res.data.data;
            this.callVisible = true;
            this.countTime();
            this.update(row);
          } else {
            Message.success("请注意接听手机电话！");
          }
        } else {
          // Message.error(res.data);
          let data = res.data.data;
          if (data) {
            Message.error(JSON.parse(data).message);
          } else {
            Message.error(res.data.message);
          }
        }
      });
    },
    //axb呼叫模式弹窗
    countTime() {
      if (this.times > 0) {
        setTimeout(() => {
          this.times -= 1;
          this.countTime();
        }, 1000);
      } else {
        this.callVisible = false;
      }
    },
    updataNumber(data) {
      update_call({
        id: data.id,
      }).then((result) => {
        console.log("更新拨打状态", result);
      });
    },
    // 释放按钮
    removeButton(data, index) {
      if (data.status == 3) {
        Message.warning("此号码为成交客户，无法释放");
        return;
      } else if (data.status == 4) {
        Message.warning("此号码为黑名单，无法释放");
        return;
      }
      this.removeIndex = index;
      let params = {
        listId: [data.id],
        roleId: data.roleId,
        departId: data.departId,
      };
      this.removeParams = params;
      this.removeVisible = true;
    },
    // 下拉事件
    oneSlt() {
      if (this.removeValue == 0) {
        this.disabled1 = false;
      } else {
      }
    },
    // 批量下拉事件
    oneSltBatch() {
      if (this.batchRemoveValue == 0) {
        this.disabled2 = false;
      } else {
      }
    },
    // 释放 公司
    confirm() {
      // 没有部门id，就去公司公海
      this.removeParams.departId = "";
      let res = phoneDelete(this.removeParams);
      res.then((res) => {
        Message.success("释放成功");
        this.tableData.splice(this.removeIndex, 1);
        this.removeVisible = false;
        this.getList();
      });
    },
    // 释放 部门
    step() {
      let res = phoneDelete(this.removeParams);
      res.then((res) => {
        Message.success("释放成功");
        this.tableData.splice(this.removeIndex, 1);
        this.getList();
      });
    },
    // 释放确认事件
    submitRemoveValue() {
      if (this.removeValue === "") return;
      // console.log(this.removeParams.departId);
      // 有部门id，就去部门
      if (this.removeValue === 0) {
        this.step();
      } else if (this.removeValue === 1) {
        this.confirm();
      } else {
      }
    },
    // 查询具体部门
    depDetails() {
      this.depVisible = true;
      this.removeVisible = false;
      this.findUser2();
    },
    // 批量查询具体部门
    depDetails1() {
      this.batchRemoveVisible = false;
      this.depVisibleBatch = true;
      this.findUser2();
    },
    // 批量释放按钮
    moreDelete() {
      if (this.numsArray.length == 0) {
        Message.warning("未选中号码");
        return;
      }
      this.batchRemoveVisible = true;
    },
    // 释放 公司
    confirm1() {
      let params = {
        listId: this.numsArray,
        roleId: this.tableData[0].roleId,
        departId: "",
      };
      let res = phoneDelete(params);
      res.then((res) => {
        Message.success("释放成功");
        this.numsArray.map((v, i) => {
          let index = this.tableData.findIndex((item) => item.id === v);
          this.tableData.splice(index, 1);
          // this.numsArray.splice(i, 1);
        });
        this.getList();
      });
    },
    // 释放 部门
    step1() {
      let params = {
        listId: this.numsArray,
        roleId: this.tableData[0].roleId,
        departId: this.departId,
      };
      let res = phoneDelete(params);
      res.then((res) => {
        Message.success("释放成功");
        this.numsArray.map((v, i) => {
          let index = this.tableData.findIndex((item) => item.id === v);
          this.tableData.splice(index, 1);
          // this.numsArray.splice(i, 1);
        });
        this.getList();
      });
    },
    // 批量释放确认事件
    submitBatchRemoveValue() {
      this.numsArray.map((v, i) => {
        this.tableData.findIndex((item) => {
          if (item.status == 3 || item.status == 4) {
            Message.warning("选中列表中包含成交客户或黑名单，无法释放");
            return;
          }
        });
      });
      if (this.batchRemoveValue === "") return;
      if (this.batchRemoveValue === 0) {
        this.step1();
      } else if (this.batchRemoveValue === 1) {
        this.confirm1();
      } else {
      }
      setTimeout(function () {
        location.reload();
      }, 1000);
    },
    //编辑提交
    updateSubmit() {
      if (this.update_status == null) {
        Message.warning("请选择状态!!!");
      } else {
        let params = {
          historyRecord: this.form.historyRecord,
          activePhone: this.form.activePhone,
          customerPhone: this.form.customerPhone,
          company: this.form.company,
          remarks: this.form.remarks,
          status: this.update_status,
          userId: this.form.userId,
          departId: this.form.departId,
          id: this.form.id,
          roleId: this.form.roleId,
          choosePeople: this.form.choosePeople,
          customerScale: this.form.customerScale,
          customerSource: this.form.customerSource,
          industry: this.form.industry,
          address: this.form.address,
        };
        let res = phoneUpdate(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
            this.updateVisible = false;
            // this.reload();
            this.getList();
          } else {
            Message.error(res.data.message);
            this.updateVisible = false;
            this.getList();
          }
        });
      }
    },
    //下载模板
    upload() {
      window.open("http://113.250.189.239:9005/phone.xls");
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      Message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定释放 ${file.name}？`);
    },
    changeUpload(file, fileList) {
      this.file = file.raw;
    },
    //批量导入按钮
    allNums() {
      this.disabled = false;
      this.numShow = true;
      this.formDate = null;
    },
    //批量导入提交
    numsSubmit() {
      this.disabled = true;
      this.numShow = false;
      let formDate = new FormData();
      Message.success("数据加载中，稍等！请勿离开页面");
      formDate.append("file", this.file);
      let res = phoneImportPhone(formDate);
      res.then((res) => {
        if (res.data.statusCode == "000000") {
          Message.success(res.data.message);
          this.file = {};
        } else {
          Message.error(res.data.message);
          this.file = {};
        }
      });
    },
    //添加号码
    addNums() {
      this.form = [];
      this.update_status = null;
      this.addOneVisible = true;
    },
    //添加号码确认
    addOneSubmit() {
      let params = {
        activePhone: this.form.activePhone,
        customerPhone: this.form.customerPhone,
        company: this.form.company,
        departName: this.form.departName,
        remarks: this.form.remarks,
        status: this.update_status,
        userId: this.id,
        choosePeople: this.form.choosePeople,
        customerScale: this.form.customerScale,
        customerSource: this.form.customerSource,
        industry: this.form.industry,
        address: this.form.address,
      };
      let res = phoneAdd(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.addOneVisible = false;
        } else {
          Message.error(res.data.message);
          this.addOneVisible = false;
        }
        this.getList();
      });
    },
  },
};
</script>

<style lang="less" scoped>
#creatNums {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
  .uploadBtn {
    width: 68%;
  }
  /deep/.el-dialog {
    width: 70%;
  }
  .update {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
    .el-form-item {
      width: 50%;
    }
  }
  .client-details {
    /deep/.el-drawer.rtl {
      height: 40%;
      bottom: 0;
      top: auto;
      .update-details {
        width: 70%;
      }
      .data-details {
        width: 300px;
        .title {
          color: #fff;
          background-color: #53a1f0;
          padding: 10px 20px;
          box-sizing: border-box;
          font-size: 18px;
          text-align: center;
        }
        .users {
          font-size: 14px;
          line-height: 30px;
        }
      }
    }
  }
  .dblclick /deep/.el-dialog__header {
    padding: 0;
  }
}
</style>